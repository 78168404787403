<template>
  <div class="customer-information-container">
    <div class="customer-information-list-tools">
      <el-form class="battery-form" :inline="true" :model="formData">
        <el-form-item label="设备编号">
          <el-input
            v-model="formData.sn"
            placeholder="设备编号"
            size="small"
            class="area-form-item"
            style="width: 140px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="信息等级">
          <el-select
            v-model="formData.faultLevel"
            placeholder="信息等级"
            size="small"
            class="area-form-item"
          >
            <el-option label="全部" :value="null"></el-option>
            <el-option label="一般" :value="0"></el-option>
            <el-option label="紧急" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="信息状态">
          <el-select
            v-model="formData.faultStatus"
            placeholder="信息状态"
            size="small"
            class="area-form-item"
          >
            <el-option label="全部" :value="null"></el-option>
            <el-option label="已处理" :value="1"></el-option>
            <el-option label="未处理" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="信息来源">
          <el-select
            v-model="formData.centerSource"
            placeholder="信息来源"
            size="small"
            class="area-form-item"
          >
            <el-option v-for="(item,index) in sourceList" :key="index" :label="item.typeName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
                v-model="formData.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="timeChange"
              >
              </el-date-picker>
        </el-form-item>
        <el-form-item style="float: right; marginright: 0px">
          <el-button type="primary" @click="getData()">搜索</el-button>
          <el-button @click="resetFrom()">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="customer-information-table-container">
      <div class="customer-information-table-content">
        <el-table style="width: 100%" height="100%" :data="list" v-loading="loading">
          <el-table-column label="设备编号" prop="sn">
            <template slot-scope="scope">
              <a @click="gotoDetail(scope.row)" style="color: blue">{{scope.row.sn}}</a>
            </template>
          </el-table-column>
          <el-table-column label="信息状态" prop="producer">
            <template slot-scope="scope">
              {{scope.row.faultStatusText || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="信息类型" prop="model">
            <template slot-scope="scope">
              {{scope.row.info || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="来源" prop="status">
            <template slot-scope="scope">
              {{scope.row.centerSourceText || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="信息等级" prop="ompStatus">
            <template slot-scope="scope">
              {{scope.row.faultLevelText || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="生成时间" prop="ompStatus">
            <template slot-scope="scope">
              {{scope.row.faultTime || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="持续时间" prop="ompStatus">
            <template slot-scope="scope">
              {{scope.row.interval || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button v-if="scope.row.faultStatus === 0" @click="setHandle(scope.row)" type="text"
                >处理</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="customer-information-table-page">
        <div class="customer-information-table-page-container">
          <el-pagination
            background
            :current-page="formData.pageIndex"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="formData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <Handle v-if="visable" ref="handleModel" @refreshDataList="getData" />
  </div>
</template>

<script>
import axios from "@/utils/axios";
import moment from "moment";
import Handle from "./components/handle";
import "./Index.less";
export default {
  name: "CustomerInformation",
  data() {
    return {
      list: [],
      total: 0,
      loading: false,
      visable: false,
      multipleCodes: [],
      multipleOrgIds: [],
      sourceList: [],
      formData: {
        sn: null,
        faultLevel: null,
        faultStatus: null,
        orderBy: null,
        pageIndex: 1,
        pageSize: 20,
        time: null,
        organId: null,
        startTime: null,
        endTime: null,
        centerSource: null,
        source: 2,
      },
    };
  },
  components: { Handle },
  mounted() {
    this.formData.organId = localStorage.getItem("organId");
    this.formData.sn = this.$route.params.sn;
    this.getData();
    this.getSourceList()
  },
  methods: {
    //获取列表数据
    getData() {
      this.loading = true
      axios
        .post(`/api/cloudControl/getCloudControlPage`, this.formData)
        .then((res) => {
          const { list, total, page } = res;
          this.list = list;
          this.total = total;
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          this.$message.error(err);
        });
    },
    //重置
    resetFrom() {
      this.formData = {
        sn: null,
        faultLevel: null,
        faultStatus: null,
        orderBy: null,
        pageIndex: 1,
        pageSize: 20,
        time: null,
        organId: localStorage.getItem("organId"),
        startTime: null,
        endTime: null,
        centerSource: null,
        source: 2,
      }
      this.getData();
    },
    //时间选择
    timeChange(data) {
      if (data) {
        this.formData.startTime = this.DateToStr(data[0]);
        this.formData.endTime = this.DateToStr(data[1]);
      }
    },
    //日期转字符串
    DateToStr(date) {
      var year = date.getFullYear(); //年
      var month = date.getMonth(); //月
      var day = date.getDate(); //日
      var hours = date.getHours(); //时
      var min = date.getMinutes(); //分
      var second = date.getSeconds(); //秒
      return (
        year +
        "-" +
        (month + 1 > 9 ? month + 1 : "0" + (month + 1)) +
        "-" +
        (day > 9 ? day : "0" + day) +
        " " +
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (min > 9 ? min : "0" + min) +
        ":" +
        (second > 9 ? second : "0" + second)
      );
    },
    //获取来源列表
    getSourceList() {
      axios
        .post(`/api/customerServiceCenter/source`)
        .then((res) => {
          this.sourceList = res
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    handleSizeChange(val) {
      this.formData.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.formData.pageIndex = val;
      this.getData();
    },
    //处置
    setHandle(item) {
      this.visable = true;
      this.$nextTick(() => {
        this.$refs.handleModel.init();
        this.$refs.handleModel.title = item.sn + " 处理";
        this.$refs.handleModel.dataForm.sn = item.sn;
        this.$refs.handleModel.dataForm.cloudId = item.id;
      });
    },
    //跳转设备详情
    gotoDetail(row) {
      this.$router.push({
        name: "DeviceDetail",
        params: {
          sn: row.sn,
          imei: row.sn,
          type: 1
        },
      });
    }
  },
};
</script>